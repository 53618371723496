import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ControlVideo from "../components/controlVideo"
import downArrow from "../assets/images/downArrow.png"
import "./styles/index.scss"
import Slider from "../components/slider"
import lottieRender from "../components/lottie"
import gallery from "../components/gallery"

const IndexPage = ({ data }) => {
  const [scrolled, setScrolled] = useState(false)
  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const [index, setIndex] = useState({
    count: 0,
    max: data.wpgraphql.pages.nodes.length - 1,
  })

  const [transition, setTransition] = useState(false)
  const [direction, setDirection] = useState(false)
  const [render, setRender] = useState(false)
  const mainRef = React.createRef()

  useEffect(() => {
    const menu = document.getElementsByClassName("menu")
    const indexPage = document.getElementsByClassName("indexPage")
    const lineIndex = document.getElementsByClassName("pageIndicatorLine")
    const secondIndex = document.getElementsByClassName("secondIndexPage")

    if (menu && indexPage) {
      menu[0].className = "menu"
      indexPage[0].className = "indexPage"
      lineIndex[0].className = "pageIndicatorLine"
      secondIndex[0].className = "secondIndexPage"
    }

    switch (index.count) {
      case 0:
        menu[0].className = "menu white"
        indexPage[0].className = "indexPage white"
        lineIndex[0].className = "pageIndicatorLine backWhite"
        secondIndex[0].className = "secondIndexPage bluest"
        const controlDOM = document.getElementsByClassName("video-control")

        if (controlDOM[0]) ReactDOM.render(<ControlVideo />, controlDOM[0])
        setRender(false)
        break
      case 1:
        if (!render) {
          Slider(scrolled, setScrolled)
          setRender(true)
        }

        break
      case 2:
        setRender(false)
        break

      case 3:
        menu[0].className = "menu white"
        indexPage[0].className = "indexPage white"
        lineIndex[0].className = "pageIndicatorLine backWhite"
        secondIndex[0].className = "secondIndexPage bluest"
        if (!render) {
          lottieRender()
          setRender(true)
        }
        break

      case 4:
        gallery(scrolled, setScrolled)
        setRender(false)

        break

      case 5:
        if (!render) {
          gallery(scrolled, setScrolled)
          setRender(false)
        }
        break
      case 6:
        setRender(false)

        break
      default:
        break
    }
  }, [index])
  const backPage = async (setted = null) => {
    setDirection("down")
    if (index.count > 0 && !mainRef.current.scrollTop) {
      setTransition(true)
      await sleep(1000)
      setted != null
        ? setIndex({ count: setted, max: index.max })
        : setIndex({ count: index.count - 1, max: index.max })
      setTransition(false)
    }
  }

  const forwardPage = async (setted = null) => {
    let topDown =
      mainRef.current.scrollHeight - mainRef.current.scrollTop ===
      mainRef.current.clientHeight
    setDirection("up")
    if (index.count < index.max && topDown) {
      setTransition(true)
      await sleep(1000)
      setted != null
        ? setIndex({ count: setted, max: index.max })
        : setIndex({ count: index.count + 1, max: index.max })
      setTransition(false)
    }
  }

  return (
    <Layout upPage={forwardPage} downPage={backPage} index={index}>
      <SEO title="Home" />
      <div className="lateralArrow" style={
        index ? (index.count !== 1 ? { display: "none" } : null) : null
      }>
        <img
          className="leftArrow"
          src={downArrow}
          width="auto"
          height="auto"
          alt="down arrow"
        />
        <img
          className="rightArrow"
          src={downArrow}
          width="auto"
          height="auto"
          alt="down arrow"
        />
      </div>
      <div
        ref={mainRef}
        className={
          direction === "up"
            ? transition
              ? "outUp mainContainer"
              : "inUp mainContainer"
            : transition
              ? "inDown mainContainer"
              : "outDown mainContainer"
        }
        dangerouslySetInnerHTML={{
          __html: data.wpgraphql.pages.nodes[index.count].content,
        }}
      ></div>

      <div
        className="downArrow"
        style={
          index ? (index.count === index.max ? { display: "none" } : null) : null
        }
      >
        <img
          className="imageArrow"
          src={downArrow}
          width="auto"
          height="auto"
          alt="down arrow"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery {
    wpgraphql {
      pages(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
        nodes {
          title(format: RENDERED)
          content(format: RENDERED)
        }
      }
    }
  }
`

export default IndexPage
