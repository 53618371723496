import React, { useState } from "react"
import "./styles/controlVideo.scss"

const PlaySymbol = () => <span>&#x25BA;</span>

const ControlVideo = () => {
  const [sound, setSound] = useState(true)
  const [play, setPlay] = useState(true)

  const videoElement = document.getElementsByClassName("video-home")[0]
    .lastChild

  const changeSound = () => {
    videoElement.muted = sound
    setSound(!sound)
  }

  const changePlay = () => {
    !play ? videoElement.play() : videoElement.pause()
    setPlay(!play)
  }

  return (
    <div className="videoController">
      <div className="controlAudio" onClick={changeSound} role="button">
        <div className="controlText">{sound ? "on" : "off"}</div>
      </div>
      <div className="playButton" onClick={changePlay} role="button" >
        {play ? "| |" : <PlaySymbol />}
      </div>
    </div>
  )
}

export default ControlVideo
