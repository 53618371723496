import Lottie from "lottie-web"
import animation from "../assets/TXT_EXPOMORRO.json"

const lottieRender = () => {
  Lottie.loadAnimation({
    container: document.getElementsByClassName("bodymovin")[0],
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData: animation,
  })
}

export default lottieRender
