const Slider = (scrolled, setScrolled) => {
  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const slideElement = document.getElementsByClassName("slider-element")
  const index = slideElement.length
  const slider = document.getElementsByClassName("slider")
  let nav = 0
  let init = true

  const slideForward = async () => {
    if (!scrolled) {
      if (nav < index - 1) {
        slideElement[nav].className = "slider-element slideElementOut show"
        setScrolled(true)
        await sleep(1000)
        slideElement[nav].className = "slider-element hide"
        slideElement[nav].firstChild.childNodes[1].className = ""
        nav++
        slideElement[nav].className = "slider-element slideElementIn show"
        setTitles()
        setScrolled(false)
        return
      }

      slideElement[nav].className = "slider-element slideElementOut show"
      setScrolled(true)
      await sleep(1000)
      slideElement[nav].className = "slider-element hide"
      slideElement[nav].firstChild.childNodes[1].className = ""
      nav = 0
      slideElement[nav].className = "slider-element slideElementIn show"
      setTitles()
      setScrolled(false)
    }
  }

  const slideBackward = async () => {
    if (!scrolled) {
      if (nav > 0) {
        slideElement[nav].className = "slider-element slideElementOut show"
        setScrolled(true)
        await sleep(1000)
        slideElement[nav].className = "slider-element hide"
        slideElement[nav].firstChild.childNodes[1].className = ""
        nav--
        slideElement[nav].className = "slider-element slideElementIn show"
        setTitles()
        setScrolled(false)
        return
      }

      slideElement[nav].className = "slider-element slideElementOut show"
      setScrolled(true)
      await sleep(1000)
      slideElement[nav].className = "slider-element hide"
      slideElement[nav].firstChild.childNodes[1].className = ""
      nav = index - 1
      slideElement[nav].className = "slider-element slideElementIn show"
      setTitles()
      setScrolled(false)
    }
  }

  const setTitles = () => {
    slideElement[nav].firstChild.childNodes[1].className = "mainTitle"

    let text =
      slideElement[nav !== index - 1 ? nav + 1 : 0].firstChild.childNodes[1]
        .innerText
    let text2 =
      slideElement[nav === 0 ? index - 1 : nav - 1].firstChild.childNodes[1]
        .innerText

    if (init) {
      init = false
      let Previus = document.createElement("h2")
      let Next = document.createElement("h2")
      Previus.innerText = text2
      Next.innerText = text
      Previus.className = "previusTitle"
      Next.className = "nextTitle"

      slider[0].appendChild(Previus)
      slider[0].appendChild(Next)

      Next.addEventListener("click", slideForward)
      Previus.addEventListener("click", slideBackward)
      return
    }

    let previus = document.getElementsByClassName("previusTitle")
    let next = document.getElementsByClassName("nextTitle")

    if (previus[0] && next[0]) {
      previus[0].innerText = nav === 1 ? text2.slice(3, 9) : text2
      next[0].innerText = nav === 2 ? text.slice(0, 5) : text
    }
  }

  setTitles()
}

export default Slider
