const gallery = (scrolled, setScrolled) => {
  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const galleryImages = document.getElementsByClassName(
    "ngg-gallery-slideshow-image"
  )

  let nav = 0
  let index = galleryImages.length

  const mainGallery = document.getElementsByClassName("galleryContainer")

  const closeExpanded = () => {
    const container = document.getElementById("container-expanded")
    if (container) container.parentNode.removeChild(container)
  }

  const openGallery = () => {
    const gallery = document.getElementsByClassName("gallery-project")

    if (gallery.length) {
      const close = document.createElement("p")
      close.innerText = "×"
      close.className = "closeButton black"

      close.addEventListener("click", closeGallery)
      gallery[0].appendChild(close)
      gallery[0].className = "gallery-project slideElementIn appear"
    }
  }

  const closeGallery = () => {
    const gallery = document.getElementsByClassName("gallery-project")

    if (gallery.length) {
      const closeBut = gallery[0].lastChild

      gallery[0].removeChild(closeBut)

      gallery[0].className = "gallery-project slideElementOut hide"
    }
  }

  const augmentImage = () => {
    const container = document.createElement("div")
    const element = document.createElement("img")
    const close = document.createElement("p")

    close.innerText = "×"

    close.className = "closeButton"

    element.src = galleryImages[nav].childNodes[1].childNodes[1].src

    console.log(galleryImages[nav].childNodes)
    element.className = "expandedImage"

    close.addEventListener("click", closeExpanded)

    container.appendChild(element)
    container.appendChild(close)

    container.addEventListener("click", closeExpanded)

    container.className = "expandedContainer"
    container.id = "container-expanded"

    document.body.appendChild(container)
  }

  const slideForward = async () => {
    if (!scrolled) {
      if (nav < index - 1) {
        galleryImages[nav].className =
          "ngg-gallery-slideshow-image slideElementOut show"
        setScrolled(true)
        await sleep(1000)
        galleryImages[nav].className = "ngg-gallery-slideshow-image hide"
        nav++
        galleryImages[nav].addEventListener("click", augmentImage)
        galleryImages[nav].className =
          "ngg-gallery-slideshow-image slideElementIn show"
        setScrolled(false)
        return
      }

      galleryImages[nav].className =
        "ngg-gallery-slideshow-image slideElementOut show"
      setScrolled(true)
      await sleep(1000)
      galleryImages[nav].className = "ngg-gallery-slideshow-image hide"
      nav = 0
      galleryImages[nav].className =
        "ngg-gallery-slideshow-image slideElementIn show"
      setScrolled(false)
    }
  }

  const slideBackward = async () => {
    if (!scrolled) {
      if (nav > 0) {
        galleryImages[nav].className =
          "ngg-gallery-slideshow-image slideElementOut show"
        setScrolled(true)
        await sleep(1000)
        galleryImages[nav].className = "ngg-gallery-slideshow-image hide"
        nav--
        galleryImages[nav].addEventListener("click", augmentImage)
        galleryImages[nav].className =
          "ngg-gallery-slideshow-image slideElementIn show"
        setScrolled(false)
        return
      }

      galleryImages[nav].className =
        "ngg-gallery-slideshow-image slideElementOut show"
      setScrolled(true)
      await sleep(1000)
      galleryImages[nav].className = "ngg-gallery-slideshow-image hide"
      nav = index - 1
      galleryImages[nav].className =
        "ngg-gallery-slideshow-image slideElementIn show"
      setScrolled(false)
    }
  }

  const forward = document.createElement("p")
  const backward = document.createElement("p")

  forward.innerText = "▶"
  backward.innerText = "◀"
  forward.className = "forwardArrow"
  backward.className = "backwardArrow"

  forward.addEventListener("click", slideForward)

  backward.addEventListener("click", slideBackward)

  mainGallery[0].appendChild(forward)
  mainGallery[0].appendChild(backward)

  // console.log(galleryImages)
  const buttonGallery = document.getElementsByClassName("gallery-open")

  if (buttonGallery.length)
    buttonGallery[0].addEventListener("click", openGallery)

  for (let index = 0; index < galleryImages.length; index++) {
    const element = galleryImages[index]
    element.childNodes[1].href = "#"
    if (index === 0) element.addEventListener("click", augmentImage)
    if (index !== 0) element.className = "ngg-gallery-slideshow-image hide"
  }
}

export default gallery
